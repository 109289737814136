// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-ambassadors-page-js": () => import("./../../../src/templates/ambassadors-page.js" /* webpackChunkName: "component---src-templates-ambassadors-page-js" */),
  "component---src-templates-b-4-tk-page-js": () => import("./../../../src/templates/b4tk-page.js" /* webpackChunkName: "component---src-templates-b-4-tk-page-js" */),
  "component---src-templates-collection-drive-page-js": () => import("./../../../src/templates/collection-drive-page.js" /* webpackChunkName: "component---src-templates-collection-drive-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-donations-page-js": () => import("./../../../src/templates/donations-page.js" /* webpackChunkName: "component---src-templates-donations-page-js" */),
  "component---src-templates-fitness-fest-page-js": () => import("./../../../src/templates/fitness-fest-page.js" /* webpackChunkName: "component---src-templates-fitness-fest-page-js" */),
  "component---src-templates-g-4-tk-page-js": () => import("./../../../src/templates/g4tk-page.js" /* webpackChunkName: "component---src-templates-g-4-tk-page-js" */),
  "component---src-templates-gear-up-page-js": () => import("./../../../src/templates/gear-up-page.js" /* webpackChunkName: "component---src-templates-gear-up-page-js" */),
  "component---src-templates-golf-tournament-page-js": () => import("./../../../src/templates/golf-tournament-page.js" /* webpackChunkName: "component---src-templates-golf-tournament-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-open-house-page-js": () => import("./../../../src/templates/open-house-page.js" /* webpackChunkName: "component---src-templates-open-house-page-js" */),
  "component---src-templates-program-providers-js": () => import("./../../../src/templates/program-providers.js" /* webpackChunkName: "component---src-templates-program-providers-js" */),
  "component---src-templates-s-4-tk-club-page-js": () => import("./../../../src/templates/s4tk-club-page.js" /* webpackChunkName: "component---src-templates-s-4-tk-club-page-js" */),
  "component---src-templates-s-4-tk-internship-page-js": () => import("./../../../src/templates/s4tk-internship-page.js" /* webpackChunkName: "component---src-templates-s-4-tk-internship-page-js" */),
  "component---src-templates-scholarships-page-js": () => import("./../../../src/templates/scholarships-page.js" /* webpackChunkName: "component---src-templates-scholarships-page-js" */),
  "component---src-templates-sponsors-page-js": () => import("./../../../src/templates/sponsors-page.js" /* webpackChunkName: "component---src-templates-sponsors-page-js" */),
  "component---src-templates-spotlights-page-js": () => import("./../../../src/templates/spotlights-page.js" /* webpackChunkName: "component---src-templates-spotlights-page-js" */),
  "component---src-templates-team-page-js": () => import("./../../../src/templates/team-page.js" /* webpackChunkName: "component---src-templates-team-page-js" */),
  "component---src-templates-testimonials-page-js": () => import("./../../../src/templates/testimonials-page.js" /* webpackChunkName: "component---src-templates-testimonials-page-js" */),
  "component---src-templates-volunteer-page-js": () => import("./../../../src/templates/volunteer-page.js" /* webpackChunkName: "component---src-templates-volunteer-page-js" */),
  "component---src-templates-w-4-tk-page-js": () => import("./../../../src/templates/w4tk-page.js" /* webpackChunkName: "component---src-templates-w-4-tk-page-js" */)
}

